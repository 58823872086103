import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://crossfittheville.files.wordpress.com/2011/02/eva_photo.jpg"
      }}><img parentName="a" {...{
          "src": "https://crossfittheville.files.wordpress.com/2011/02/eva_photo.jpg?w=615",
          "alt": null,
          "title": "eva_photo"
        }}></img>{`{.alignnone
.size-full
.wp-image-70}`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://mobilitywod.blogspot.com"
      }}>{`http://mobilitywod.blogspot.com`}</a></p>
    <p>{`Check out this great site for some extra Mobility Work, This is a great
tool to add to your CrossFit Workouts.`}</p>
    <p>{`     SUNDAY`}</p>
    <p>{` ” REST DAY”`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      